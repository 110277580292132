import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import styles from "./contnt.module.css";
import Footer from "../../../components/HomePage/Footer";
import Navbar from "../../../components/NavBar";

export default function Index() {
  const elementsRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = elementsRef.current;

      tags.forEach((tag) => {
        if (tag.offsetTop < pageBottom) {
          tag.classList.add(styles.visible);
        } else {
          tag.classList.remove(styles.visible);
        }
      });
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Navbar />
      <div className={styles.main}>
        <div className={`${styles.main_most}`}>
          
          {/* First Content Block */}
          <div className={`${styles.main_in}`}>
            <div>
              <h
                ref={(el) => (elementsRef.current[0] = el)}
                className={`${styles.fadein}`}
              >
                We are hiring social media executive
              </h>
            </div>
            <div>
              <p
                ref={(el) => (elementsRef.current[42] = el)}
                className={`${styles.fadein}`}
              >
                We are seeking a creative and results-driven Social Media Executive to join our team. If you have a passion for social media, content creation, and community engagement, we want to hear from you!
              </p>
              <p
                ref={(el) => (elementsRef.current[2] = el)}
                className={`${styles.fadein}`}
              >
                <h1>Responsibilities:</h1><br></br>
                Develop and implement social media strategies across platforms.<br></br>
                Create engaging and creative content for posts, stories, and ads.<br></br>
                Monitor and analyze performance metrics to optimize campaigns.<br></br>
                Stay updated on the latest social media trends and tools.<br></br>
                Interact with followers, respond to comments, and grow our online community.
              </p>
              <p
                ref={(el) => (elementsRef.current[3] = el)}
                className={`${styles.fadein}`}
              >
                {/*Lorem ipsum dolor sit.*/}
              </p>

              {/* First Apply Button */}
              <Link to="/apply-graphic-designer">
                <button
                  ref={(el) => (elementsRef.current[4] = el)}
                  className={`${styles.fadein}`}
                >
                  APPLY NOW - Social Media Executive
                </button>
              </Link>
            </div>
          </div>

          {/* Second Content Block */}
          <div className={`${styles.main_in}`}>
            <div>
              <h
                ref={(el) => (elementsRef.current[10] = el)}
                className={`${styles.fadein}`}
              >
                We are hiring Sales executive
</h>
            </div>
            <div>
              <p
                ref={(el) => (elementsRef.current[11] = el)}
                className={`${styles.fadein}`}
              >
We are seeking a results-oriented Sales Executive to help drive our business growth. This role is open to candidates from freelance platforms, offering flexibility and the opportunity to work on a project or commission basis.
</p>
              <p
                ref={(el) => (elementsRef.current[12] = el)}
                className={`${styles.fadein}`}
              >
                <h1>Responsibilities:</h1><br></br>
                Identify and pursue new sales opportunities through various channels, including freelance platforms.<br></br>
                Build and maintain strong client relationships.<br></br>
                Present and promote our products/services to prospective clients.<br></br>
                Negotiate contracts and close deals to meet or exceed sales targets.<br></br>
                Provide feedback and market insights to the team.
              </p>
              <p
                ref={(el) => (elementsRef.current[13] = el)}
                className={`${styles.fadein}`}
              >
                {/*Lorem ipsum dolor sit. */}
              </p>

              {/* Second Apply Button */}
              <Link to="/apply-graphic-designer">
                <button
                  ref={(el) => (elementsRef.current[14] = el)}
                  className={`${styles.fadein}`}
                >
                  APPLY NOW - Sales Executive
                </button>
              </Link>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
}
